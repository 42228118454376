import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import { useMediaQuery } from 'react-responsive';

import Button from 'components/button';
import Icon from 'components/icon';
import Menu from 'components/menu';

import useListingsNavigate from '../utils/useListingsNavigate';
import ContactUsModal from './contact-us';

const Wrapper = styled.div`
  height: 60px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  flex-shrink: 0;
`;

const Action = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  color: var(--color-font-primary);
  &:hover {
    background-color: #f5f5f5;
  }
`;

const RightActions = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
`;

const PrimaryAction = styled(Button)`
  background-color: #00a1ff;
  padding: 8px 16px;
  color: #fff;
  border-radius: 4px;
  &:hover {
    background-color: ${darken(0.09, '#00a1ff')};
  }
  &.secondaryBtn {
    background-color: #fff;
    color: #444;
    padding: 0px 5px;
    &:hover {
      color: #00a1ff;
    }
  }
`;
const HiddenLink = styled.a`
  display: none;
`;
const BreadCrumb = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  flex: 1;
  > button {
    margin: 0;
    width: auto;
  }
`;

const Logo = styled.div`
  width: 120px;
  height: 80%;
  background-image: url(${({ url }) => url});
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
`;

export default ({
  orgId,
  location,
  floorplan,
  onClose,
  onNextBack,
  showNavButtons,
  isFullPage
}) => {
  const linkRef = React.useRef();
  const navigate = useListingsNavigate();
  const [contactOpen, setContactOpen] = React.useState(false);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const handleShare = () => {
    linkRef?.current?.click?.();
  };

  const handleContactUs = () => setContactOpen(true);

  //TODO: VARY the show/contact/apply buttons based on level? or do they get hidden all together
  const {
    handleWaitList,
    handleApply,
    handleShowing,
    options,
    contactUsEnabled,
    applyEnabled,
    showingEnabled,
    waitListEnabled
  } = React.useMemo(() => {
    const representativeItem = floorplan || location;

    const handleApply = () => {
      // This goes to app create, it will bounce and redirect as needed
      window.open(
        `/${location.companyInfo.id}/applicant/app/applications/form/create/?cId=${representativeItem._id}`,
        '_blank'
      );
    };
    const handleShowing = () => {
      if (['custom', 'rently'].includes(representativeItem.aptlyShowings?.type)) {
        // This goes to app create, it will bounce and redirect as needed
        window.open(representativeItem.aptlyShowings.link, '_blank');
      } else {
        // This goes to app create, it will bounce and redirect as needed
        window.open(
          `/${location.companyInfo.id}/showing/app/showing/create/?cId=${representativeItem._id}`,
          '_blank'
        );
      }
    };
    const handleWaitList = () => {
      window.open(representativeItem?.waitList?.link, '_blank');
    };
    const contactUsEnabled = !!representativeItem?.aptlyListings?.contactFormUrl;
    const showingEnabled = !!representativeItem?.aptlyShowings?.active;
    const applyEnabled = !!representativeItem?.aptlyScreening?.active;
    const waitListEnabled =
      !!representativeItem?.waitList?.link &&
      !!representativeItem?.waitList?.enabled &&
      !applyEnabled;

    const options = [
      {
        label: 'Share',
        icon: 'fa-duotone fa-solid fa-arrow-up-from-bracket',
        onClick: handleShare
      }
    ];

    if (contactUsEnabled) {
      options.push({
        label: 'Contact Us',
        icon: 'fa-solid fa-circle-info',
        onClick: handleContactUs
      });
    }

    return {
      handleWaitList,
      handleApply,
      handleShowing,
      waitListEnabled,
      applyEnabled,
      contactUsEnabled,
      showingEnabled,
      handleContactUs,
      options
    };
  }, [location, floorplan]);

  return (
    <Wrapper>
      {isFullPage && location?.companyInfo?.logo && <Logo url={location?.companyInfo?.logo} />}
      {contactOpen && (
        <ContactUsModal
          open={true}
          width="1200px"
          height="98%"
          maxHeight="100%"
          url={location?.aptlyListings?.contactFormUrl}
          onClose={() => setContactOpen(false)}
        />
      )}
      {!isFullPage && (
        <Action onClick={onClose}>
          <Icon name="fa-solid fa-xmark" />
        </Action>
      )}
      {showNavButtons && !isFullPage && (
        <>
          <Action marginLeft={20} onClick={() => onNextBack('back')}>
            <Icon name="fa-solid fa-arrow-left" />
          </Action>
          <Action onClick={() => onNextBack('next')}>
            <Icon name="fa-solid fa-arrow-right" />
          </Action>
        </>
      )}
      {!!(location && floorplan) && (
        <BreadCrumb>
          {location && (
            <Action
              onClick={() =>
                navigate(
                  isFullPage ? `${orgId}/property/${location?._id}` : `${orgId}/${location?._id}`
                )
              }>
              {location?.displayInfo?.title}
            </Action>
          )}
          {!!floorplan && (
            <>
              <div>&gt;</div>
              <Action
                onClick={() =>
                  navigate(
                    isFullPage
                      ? `${orgId}/property/${location?._id}/${floorplan._id}`
                      : `${orgId}/${location?._id}/${floorplan._id}`
                  )
                }>
                {floorplan?.displayInfo?.title}
              </Action>
            </>
          )}
        </BreadCrumb>
      )}
      <RightActions>
        {!isMobile && contactUsEnabled && (
          <PrimaryAction className="secondaryBtn" onClick={handleContactUs}>
            Contact Us
          </PrimaryAction>
        )}
        {showingEnabled && <PrimaryAction onClick={handleShowing}>Tour</PrimaryAction>}
        {applyEnabled && <PrimaryAction onClick={handleApply}>Apply</PrimaryAction>}
        {waitListEnabled && <PrimaryAction onClick={handleWaitList}>Wait List</PrimaryAction>}
        {!isMobile && (
          <Action onClick={handleShare}>
            <Icon name="fa-duotone fa-solid fa-arrow-up-from-bracket" />
          </Action>
        )}
        {isMobile && (
          <Menu options={options}>
            {(provided) => (
              <Action {...provided}>
                <Icon name="fa-solid fa-ellipsis-vertical" />
              </Action>
            )}
          </Menu>
        )}
        <HiddenLink
          ref={linkRef}
          target="_blank"
          rel="noopener"
          noreferrer={true}
          href={`mailto:?subject=${encodeURIComponent(
            `Check out this property I've found online`
          )}&body=${encodeURIComponent(
            `${location.displayInfo?.title || ''}: ${window.location.href}`
          )}`}>
          &nbsp;
        </HiddenLink>
      </RightActions>
    </Wrapper>
  );
};
