import React, { useEffect } from 'react';
import { useNavigateWithContext } from '../../state';

export const MobileRedirect = () => {
  const navigate = useNavigateWithContext();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const audience = queryParams.get('audience');
    const code = queryParams.get('code');

    let appUrl = '';
    if (audience === 'tourResidence') {
      appUrl = `com.aptlymobile.aptlytourresident://shareCode/${code}`;
    }
    if (audience === 'tourManager') {
      appUrl = `com.getaptly.aptlytourmanager://shareCode/${code}`;
    }

    // Try to open the app
    window.location.href = appUrl;

    // Set a timeout to redirect to the fallback URL if the app doesn't open
    const timeoutId = setTimeout(() => {
      navigate(`home/login?ref=${code}`); // Redirect to the home page
    }, 2000); // Adjust the timeout as needed

    // Cleanup the timeout on component unmount
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div>
      <p>
        If the app doesn't open, <a href="https://www.example.com">click here</a>.
      </p>
    </div>
  );
};

export default MobileRedirect;
