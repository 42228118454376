import React from 'react';
import { useParams } from 'react-router-dom';
import { GET_DOCUMENT, UPDATE_DOCUMENT } from 'dal/eSignature';

const testAllBlocksSigned = ({ pages, user }) => {
  return pages
    .map((p) => p.blocks)
    .filter(Boolean)
    .flat()
    .filter((block) => {
      const isOwner = block.ownerUuid === user.uuid;
      const isNotCheckbox = !['checkbox'].includes(block.type);
      const isRequired = ['signature', 'initials'].includes(block.type) || block.required === true;
      return isOwner && isNotCheckbox && isRequired;
    })
    .every((block) => {
      if (block.type === 'dropdown' && block.dropdownMulti) {
        return (block.value || []).length > 0 ? true : false;
      }
      return [undefined, null, ''].includes(block.value) === false;
    });
};

const isBlockCompleted = (block) => {
  if (block.type === 'checkbox') return true;
  if (block.type === 'dropdown' && block.dropdownMulti) {
    return (block.value || []).length > 0 ? true : false;
  }
  return [undefined, null, ''].includes(block.value) ? false : true;
};

export const useFetchDocument = () => {
  const [data, setData] = React.useState();
  const [failed, setFailed] = React.useState(false);
  const { documentId, token } = useParams();
  const [refetchToken, setRefetchToken] = React.useState();
  const [isFetching, setFetching] = React.useState();
  const [showSuccessScreen, setShowSuccessScreen] = React.useState(false);
  const [linkExpired, setLinkExpired] = React.useState();

  const getDocument = async () => {
    if (isFetching) return;
    setFetching(true);
    setLinkExpired(false);
    try {
      const data = await GET_DOCUMENT({ documentId, token });
      setFetching(false);
      if (!data) setFailed(true);
      setData(data);
    } catch (error) {
      if (error?.data?.code === 'LINK_EXPIRED') {
        setLinkExpired(true);
      }
      setFetching(false);
      setFailed(true);
    }
  };

  React.useEffect(() => {
    getDocument();
    document.title = 'Review and sign doc';
  }, [refetchToken]);

  return {
    linkExpired,
    failed,
    showSuccessScreen,
    loading: !failed && !data,
    refreshDocument: () => setRefetchToken(new Date.getTime()),
    params: { token, documentId },
    updateDocument: (document) => setData((s) => ({ ...s, document })),
    signComplete: (status = true) => setShowSuccessScreen(status),
    backToDocument: () => {
      setRefetchToken(new Date().getTime());
      setShowSuccessScreen(false);
    },
    ...(data || {})
  };
};

export const useUiState = ({ user, doc }) => {
  const requiredSignaturePages = () => {
    const { pages } = doc;
    const word = `data-user-uuid="${user.uuid}"`;
    const re = new RegExp(word, 'g');
    return pages.reduce((final, page) => {
      const zones = page.mergeHtml.match(re);
      if (!zones || zones.length === 0) return final;
      return {
        ...final,
        [page.uuid]: zones.reduce((z, el, index) => ({ ...z, [`zone${index}`]: false }), {})
      };
    }, {});
  };

  const localStorageKey = `${doc._id}_${user.uuid}_savedChanges`;

  const [state, setState] = React.useState(() => {
    const initState = {
      showAgreement: user.showElectronicAgreement,
      activePage: 0,
      showPad: false,
      readPages: [],
      statusPaneTab: 'recipients',
      signature: undefined,
      padState: undefined,
      showSignatures: false,
      location: undefined,
      floatingMenuPages: false,
      showMenuPages: true,
      signedStatus: doc.builder === 'html' && requiredSignaturePages(),
      pages: doc.pages,
      focusedBlock: '',
      completeAssistant: false
    };

    if (!doc.isExpired && user.requireSignature && !user.hasSigned && doc.builder === 'pdf') {
      const savedChanges = JSON.parse(localStorage.getItem(localStorageKey)) || [];
      initState.pages = doc.pages.map((page) => {
        const storedPage = savedChanges.find((p) => p.uuid === page.uuid) || {};
        return {
          ...page,
          blocks: page.blocks.map((block) => {
            if (block.category !== 'fillable' || ['signature', 'initials'].includes(block.type)) {
              return block;
            }
            const storedBlock = (storedPage.blocks || []).find((b) => b.uuid === block.uuid);
            return { ...block, value: storedBlock?.value };
          })
        };
      });
    }

    return initState;
  });

  React.useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(state.pages));
  }, [state]);

  const updatePage = (page) => {
    setState((state) => ({
      ...state,
      pages: state.pages.map((p) => {
        if (p.uuid !== page.uuid) return p;
        return { ...p, ...page };
      })
    }));
  };

  const updateUi = (update = {}) => setState((s) => ({ ...s, ...update }));
  const currentPage = state?.pages[state.activePage];
  const prevPageDisabled = state.activePage === 0;
  const nextPageDisabled = state.activePage === state?.pages?.length - 1;

  const nextPage = () => {
    if (nextPageDisabled) return;
    updateUi({ activePage: state.activePage + 1 });
  };
  const prevPage = () => {
    if (prevPageDisabled) return;
    updateUi({ activePage: state.activePage - 1 });
  };

  const setActivePage = (uuid) => {
    const pageIndex = doc.pages.findIndex((p) => p.uuid === uuid);
    updateUi({ activePage: pageIndex, showSignatures: false });
  };

  const removeSignedPage = ({ pageUuid, zoneKey }) => {
    setState((s) => ({
      ...s,
      signedStatus: {
        ...s.signedStatus,
        [pageUuid]: {
          ...s.signedStatus[pageUuid],
          [zoneKey]: false
        }
      }
    }));
  };

  const signPage = ({ pageUuid, zoneKey }) => {
    setState((s) => ({
      ...s,
      signedStatus: {
        ...s.signedStatus,
        [pageUuid]: {
          ...s.signedStatus[pageUuid],
          [zoneKey]: true
        }
      }
    }));
  };

  React.useEffect(() => {
    if (!currentPage || state.readPages.includes(currentPage.uuid)) return;
    setState((s) => ({ ...s, readPages: [...s.readPages, currentPage.uuid] }));
  }, [currentPage?.uuid]);

  const allSigned =
    doc.builder === 'pdf'
      ? testAllBlocksSigned({ pages: state.pages, user })
      : Object.values(state.signedStatus)
          .map((p) => Object.values(p))
          .flat()
          .every((s) => s === true);

  React.useEffect(() => {
    if (window.navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((location) => {
        if (!location.coords) return;
        updateUi({ location: { lat: location.coords.latitude, long: location.coords.longitude } });
      });
    }
  }, []);

  const fillableBlocks = React.useMemo(() => {
    if (doc.builder !== 'pdf') return [];
    return state.pages
      .map((page, i) => {
        return page.blocks
          .filter((b) => b.category === 'fillable' && b.ownerUuid === user.uuid)
          .map((b) => ({
            ...b,
            pageIndex: i,
            page,
            isCompleted: isBlockCompleted(b),
            isRequired: ['signature', 'initials'].includes(b.type) || b.required === true
          }))
          .sort((a, b) => a.rect.top - b.rect.top);
      })
      .flat();
  }, [state.pages, doc]);

  const setFocusedBlock = (dir) => {
    let nextIndex = 0;
    const currentIndex = fillableBlocks.findIndex(
      (b) => `${b.uuid}_${b.page.uuid}` === state.focusedBlock
    );

    if (dir === 'prev' && currentIndex > 1) nextIndex = currentIndex - 1;
    if (dir === 'next' && currentIndex < fillableBlocks.length - 1 && currentIndex > -1) {
      nextIndex = currentIndex + 1;
    }

    const block = fillableBlocks[nextIndex];
    const jumpToBlock = () => {
      const el = document.getElementById(`${block.uuid}_${block.page.uuid}`);
      el?.scrollIntoView({ block: 'center' });
    };
    updateUi({ focusedBlock: `${block.uuid}_${block.page.uuid}` });
    jumpToBlock();
  };

  return {
    currentPage,
    prevPageDisabled,
    nextPageDisabled,
    updateUi,
    nextPage,
    prevPage,
    allRead: state.readPages.length === doc?.pages?.length,
    setActivePage,
    ...state,
    removeSignedPage,
    signPage,
    allSigned,
    updatePage,
    fillableBlocks,
    setFocusedBlock
  };
};
